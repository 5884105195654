import { LitElement, html, nothing } from 'lit';
import { property } from 'lit/decorators.js';

// Components
import('./desktop/desktopHeader');
import('./mobile/mobileHeader');
import('./templates/loading');

// Utils
import debounce from '../../utils/debounce';
import { isDataStale, setData } from '../../utils/data';

// Constants
import { novantHealthUrl } from '../../constants';

// Types
import { IHeaderData } from '../../types/types';

export class AuroraHeader extends LitElement {
  @property({ type: Object, attribute: false })
  headerdata: IHeaderData = {
    navigationItems: [{ contentType: '' }],
  };

  @property({ type: Boolean, attribute: 'enableLocationFinder' }) enableLocationFinder = true;

  /*
  * This prop outside of the headerdata object makes implementation easier for the consumer of the component
  * to set this prop directly on the component tag. This prop is used to determine if the header should reload user changes their location.
  */ 
  @property({ type: Boolean, reflect: true }) reloadOnLocationChange = false;

  @property({ type: Boolean, attribute: 'hide' }) _hide = false;

  @property({ type: Number, state: true, attribute: false }) _pageWidth = window.innerWidth;

  @property({ type: Boolean, state: true }) _loading = false;

  private async fetchDefaultData(url: string) {
    if (isDataStale('navigation', 'aurora-nav')) {
      this._loading = false;
      this.headerdata = JSON.parse(sessionStorage.getItem('navigation'));
    } else {
      this._loading = true;
      const response = await fetch(url);
      const json = await response.json();
      this.headerdata = json.header;
      this._loading = false;
      setData('navigation', this.headerdata, 'aurora-nav');
    }
  }

  private handleResize = () => {
    if (window.navigator.userAgent.includes('Samsung')) {
      this._pageWidth = document.body.clientWidth;
    } else {
      this._pageWidth = window.innerWidth;
    }
    this.requestUpdate();
  };

  async connectedCallback() {
    super.connectedCallback();
    window.addEventListener('resize', debounce(this.handleResize, 200));

    // check query params for 'hide-aurora-header' and set _hide to true if found
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('hide-aurora-header')) {
      this._hide = true;
    }

    await this.updateComplete;

    if (this.getAttribute('headerdata') === null) {
      await this.fetchDefaultData(`${novantHealthUrl}/api/navigation/header`);
    }

    this.enableLocationFinder = this.getAttribute('enableLocationFinder') !== 'false';

    const attr = this.getAttribute('headerdata');
    if (attr !== null && !Object.keys(attr).includes('loaded')) {
      this.headerdata = JSON.parse(this.getAttribute('headerdata'));
      this.setAttribute('headerdata', '{"loaded": true}');
    }
  }

  disconnectedCallback() {
    window.removeEventListener('resize', this.handleResize);
    super.disconnectedCallback();
  }

  render() {
    if (this._hide) {
      return nothing;
    }

    // According to the documentation, we need to accommodate 4 links of up to 12 characters.
    // This breakpoint (1420px) seems to be the best fit to meet the documentation.
    return this._loading
      ? html`<header-loading></header-loading>`
      : this._pageWidth >= 1560
      ? html`<desktop-header
          .desktopdata="${this.headerdata}"
          enableLocationFinder="${this.enableLocationFinder}"
          ?reloadOnLocationChange="${this.reloadOnLocationChange}"
        ></desktop-header>`
      : html`<mobile-header
          .mobiledata="${this.headerdata}"
          enableLocationFinder="${this.enableLocationFinder}"
          ?reloadOnLocationChange="${this.reloadOnLocationChange}"
        ></mobile-header>`;
  }
}
